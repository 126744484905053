exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-product-design-js": () => import("./../../../src/pages/portfolio/product-design.js" /* webpackChunkName: "component---src-pages-portfolio-product-design-js" */),
  "component---src-pages-styles-js": () => import("./../../../src/pages/styles.js" /* webpackChunkName: "component---src-pages-styles-js" */),
  "component---src-pages-work-3-d-sketchbook-js": () => import("./../../../src/pages/work/3d-sketchbook.js" /* webpackChunkName: "component---src-pages-work-3-d-sketchbook-js" */),
  "component---src-pages-work-agoura-js": () => import("./../../../src/pages/work/agoura.js" /* webpackChunkName: "component---src-pages-work-agoura-js" */),
  "component---src-pages-work-bar-eclipse-js": () => import("./../../../src/pages/work/bar-eclipse.js" /* webpackChunkName: "component---src-pages-work-bar-eclipse-js" */),
  "component---src-pages-work-bold-bevy-design-system-js": () => import("./../../../src/pages/work/bold/bevy-design-system.js" /* webpackChunkName: "component---src-pages-work-bold-bevy-design-system-js" */),
  "component---src-pages-work-bold-build-with-bold-js": () => import("./../../../src/pages/work/bold/build-with-bold.js" /* webpackChunkName: "component---src-pages-work-bold-build-with-bold-js" */),
  "component---src-pages-work-bold-js": () => import("./../../../src/pages/work/bold.js" /* webpackChunkName: "component---src-pages-work-bold-js" */),
  "component---src-pages-work-eternal-js": () => import("./../../../src/pages/work/eternal.js" /* webpackChunkName: "component---src-pages-work-eternal-js" */),
  "component---src-pages-work-gesture-icons-js": () => import("./../../../src/pages/work/gesture-icons.js" /* webpackChunkName: "component---src-pages-work-gesture-icons-js" */),
  "component---src-pages-work-health-icons-js": () => import("./../../../src/pages/work/health-icons.js" /* webpackChunkName: "component---src-pages-work-health-icons-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-keys-js": () => import("./../../../src/pages/work/keys.js" /* webpackChunkName: "component---src-pages-work-keys-js" */),
  "component---src-pages-work-logo-portfolio-js": () => import("./../../../src/pages/work/logo-portfolio.js" /* webpackChunkName: "component---src-pages-work-logo-portfolio-js" */),
  "component---src-pages-work-type-sketchbook-js": () => import("./../../../src/pages/work/type-sketchbook.js" /* webpackChunkName: "component---src-pages-work-type-sketchbook-js" */),
  "component---src-pages-☮-js": () => import("./../../../src/pages/☮.js" /* webpackChunkName: "component---src-pages-☮-js" */)
}

